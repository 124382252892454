import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./events.css";
import apiUrl from "../ApiUrl/ApiUrl";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import alertIcon from "../../images/alert.png";
import { Button, Modal } from "react-bootstrap";
import { BlockPicker } from "react-color";

export const Events = () => {
  const [show, setShow] = useState(false);
  const[shows,setshows] = useState(false);

  const ToggleShow = () => {
    setShow(!show)
  }
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();

  const [category, setCategory] = useState([]);
  const [status, setStatus] = useState();
  const [userId, setUserId] = useState();
  const [imageFile, setImageFile] = useState(undefined);
  const [blockPickerColor, setBlockPickerColor] = useState("#37d67a");

  console.log(imageFile,"changedimagr")
  const [updatedValue, setUpdatedValue] = useState();
  const [categoryId, setCategoryId] = useState();
  console.log(categoryId,"Checking the catid")
  const [loading, setLoading] = useState(true);
  const [imgurl, setimgurl] = useState("")
  const [imgurl2, setimgurl2] = useState("")
  const [color,setColor]=useState('#FF0000')
  let q = "";

  const uploadImages = async (values, b,color) => {
    const data = new FormData();
    const filename = Date.now() + imageFile.name;
    data.append("name", filename);
    data.append("photo", imageFile);
    // q = filename;
    try {
      const res = await axios.post("https://enterbkapi.herokuapp.com/upload/image", data);
      console.log(res.data);
      if (b) {
        setimgurl(res.data.url)
        return res.data.url
      } else addCategory(values, res.data.url,color);
    } catch (err) {
      console.log(err);
    }

  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(apiUrl + "category");
      const data = await response.json();
      setCategory(data);
      setLoading(false);
    };
    fetchData();
  }, []);

  const addCategory = async (value, q) => {
    const result = await axios.post(apiUrl + "category", {
      name: value.name,
      main_image: q,
      colorcode:blockPickerColor

    });
    setStatus(result.data.status);
    if (result.data.status === true) {
      window.location.reload();
    }
  };
  const deleteCategory = async (id) => {
    const result = await axios.delete(apiUrl + `category/delete/${id}`);
    console.log(result.data);
  };
  const updateCategory = async () => {

    const result = await axios.put(apiUrl + `category/update/${categoryId}`, {
      id: categoryId,
      name: updatedValue,
      main_image: imageFile !== undefined && imageFile
    })
window.location.reload()  };
  const handleUpdatedCategory = (


  ) => {
    category[0].name = updatedValue;
    if (imageFile !== undefined) {

      uploadImages(imageFile, true).then((e) => {
        category[0].main_image = e
        updateCategory();
      })

    }
    else {
      category[0].main_image = imgurl2
      updateCategory();
    }
  };
  const removeData = (id) => {
    setCategory(category.filter((item) => item.id !== id));
    deleteCategory(id);
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      main_image: "",
    },
    onSubmit: (values) => {
      console.log(values);
      uploadImages(values);
    },
    validationSchema: yup.object({
      name: yup
        .string()
        .min(3, "must be 3 character or more")
        .required("Category Name is required."),
    }),
  });
  return (
    <>
      {/* add category modal */}
      <div
        className="modal fade"
        id="categoryModal"
        tabindex="-1"
        aria-labelledby="categoryModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="categoryModalLabel">
                Add Category
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="modal-body">
                <div className="user-input">
                  <p>Category Name:</p>
                  <input
                    type="text"
                    placeholder="Enter Category Name"
                    id="name"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    required
                  />
                </div>
                {formik.errors.name ? (
                  <div className="error">{formik.errors.name}</div>
                ) : (
                  ""
                )}
                    <div>
            <div  onClick={() => ToggleShow()}>
              <label className="form-label">Marker Color*</label>
              <input
                type="text"
                className="form-control"
                id="subTitle"
                value={blockPickerColor}
                onChange={(color) => {
                  setBlockPickerColor(color.hex);
                }}
                required
              />
              
            </div>


{show ?
              <BlockPicker
                color={blockPickerColor}
                onChange={(color) => {
                  setBlockPickerColor(color.hex);
                }}
              />
              :
              <></>
            }
              </div>
                <div className="user-input">
                  <p>Upload Image:</p>
                  <input
                    type="file"
                    id="main_image"
                    onChange={(event) => {
                      setImageFile(event.target.files[0]);
                    }}
                    required
                  />
                </div>
              </div>
              <div className="modal-footer">
                {status === true ? (
                  <p className="success">Category save successfully</p>
                ) : status === false ? (
                  <p className="failed">Category save failed</p>
                ) : (
                  ""
                )}
                <button type="submit" className="btn btn-primary">
                  Save changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Delete modal */}
      <div
        className="modal fade"
        id="deleteModal"
        tabindex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header border-bottom-0 mb-0"></div>
            <div className="modal-body">
              <div className="alert-img">
                <img src={alertIcon} alt="alert" />
              </div>
              <p className="h5 text-center">
                Are you Sure you want to delete category..?
              </p>
            </div>
            <div className="modal-footer border-top-0">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => removeData(userId)}
                data-bs-dismiss="modal"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* edit category modal */}
      <Modal show={shows} onHide={()=>setshows(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body px-4">
            <div className="user-input">
              <p>Edit Category</p>
              <input
                type="text"
                name="txtUpdatedValue"
                // placeholder={value}
                onChange={(e) => setUpdatedValue(e.target.value)}

              />
            </div>
        
            <div className="user-input">
              <p>Upload Image:</p>
              <input
                type="file"
                id="main_image"
                onChange={(event) => {
                  setImageFile(event.target.files[0]);
                }}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setshows(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={
            ()=>{
              updateCategory()
              // window.location.reload()
              setshows(false)
              // window.location.reload()
            }
            
            }>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>


      {/* main div */}
      <div className="events-table">
        <div className="add-users add-category">
          <button
            className="mx-4"
            type="button"
            onClick={() => navigate("/events/create-events")}
          > 
           Add New Event
          </button>
          <button
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#categoryModal"
          >
            Add Category
          </button>
        </div>
        <fieldset className="category-fieldset">
          <legend>Categories</legend>
          <ol>
            {loading ? (
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : category.length === 0 ? (
              "No Category Found"
            ) : (
              category.map((item) => (
                <div className="events-name user-name" key={item.id}>
                  <li
                    onClick={() =>
                      navigate(`events-details/${btoa(item.id)}`, {
                        state: { name: item.name },
                      })
                    }
                  >
                    {item.name}
                  </li>
                  <div className="events-btn">
                    <button
                      type="button"
                      onClick={() => {
                        setimgurl2(item.main_image)
                        setCategoryId(item.id)
                        setshows(true)
                        
                      }}
                    >
                      Edit
                    </button>
                    <button
                      data-bs-toggle="modal"
                      data-bs-target="#deleteModal"
                      onClick={() => setUserId(item.id)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              ))
            )}
          </ol>
        </fieldset>
      </div>
    </>
  );
};

import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "./sidebar.css";
import axios from "axios";
import apiUrl from "../ApiUrl/ApiUrl";
import { FaHeart} from "react-icons/fa";
import {AiFillDashboard,AiOutlineUser,AiFillCalendar,AiOutlineUsergroupAdd,AiFillNotification} from "react-icons/ai";
import { FcFeedback,FcAcceptDatabase } from "react-icons/fc";
import Pencil from '../../images/beenter.png'
export const Sidebar = () => {
  const [notification, setNotification] = useState();
  const [status, setStatus] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()

  const sendNotification = async () => {
    const result = await axios.post(apiUrl + "push/push/notify", {
      message: notification,
    });
    setStatus(result.data);
    setLoading(false);
  };

  return (
    <>
      {/* send notification modal */}
      <div
        className="modal fade"
        id="notificationModal"
        tabindex="-1"
        aria-labelledby="notificationModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="notificationModalLabel">
                Send Notification
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body px-4">
              <div className="user-input">
                <input
                  type="text"
                  name="txtUpdatedValue"
                  placeholder="Write Notification"
                  onChange={(e) => setNotification(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="modal-footer">
              {loading === true ? (
                <div class="spinner-grow text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              ) : (
                <p>
                  {status && status.status === true
                    ? "Notification Sent"
                    : status && status.status === false
                      ? "Notification not sent"
                      : ""}
                </p>
              )}
              <button
                type="submit"
                className="btn btn-primary"
                onClick={() => {
                  sendNotification();
                  setLoading(true);
                }}
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* main div */}
      <div className="sidebar-main">
        <img src={Pencil} style={{width:'160px',marginBottom:'35%',height:'40px'}}/>
        <div className="sidebar-link">
          <NavLink
            to="/dashboard"
            className={({ isActive }) => (isActive ? "active " : "inactive")}
          >
            <button>Dashbaord</button>
          </NavLink>
          <NavLink
            to="/users"
            className={({ isActive }) => (isActive ? "active " : "inactive")}
          >
            <button>Users</button>
          </NavLink>
          <NavLink
            to="/events"
            className={({ isActive }) => (isActive ? "active " : "inactive")}
          >
            <button>Events</button>
          </NavLink>
          <NavLink
            to="/booking"
            className={({ isActive }) => (isActive ? "active " : "inactive")}
          >
            <button>Booking</button>
          </NavLink>
          <NavLink
            to="/app-users"
            className={({ isActive }) => (isActive ? "active " : "inactive")}
          >
            <button>App Users</button>
          </NavLink>
          <NavLink
            to=""
            data-bs-toggle="modal"
            data-bs-target="#notificationModal"
            className={({ isActive }) => (isActive ? "active " : "inactive")}
          >
            <button>Notification</button>
          </NavLink>

          <NavLink
            to="/feedback"
            className={({ isActive }) => (isActive ? "active " : "inactive")}
          >
            <button>Feedback</button>
          </NavLink>

        </div>
        <NavLink style={{ textDecoration: 'none' }}
            className={({ isActive }) => (isActive ? "inactive " : "inactive")}

        >
          <div className="sidebar-link logout">
            <button
              onClick={() => {
                localStorage.removeItem("serialized")
                window.location.reload()
                navigate("/")
              }}
            >Logout</button>
          </div>
        </NavLink>
      </div>
    </>
  );
};

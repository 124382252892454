// MyGoogleMaps.js
import React, { Component } from 'react';

import GoogleMapReact from 'google-map-react';

import styled from 'styled-components';
import AutoComplete from './AutoComplete';
import Marker from './Marker';

const Wrapper = styled.main`
  width: 100%;
  height: 100%;
`;

class MyGoogleMap extends Component {


    state = {
        mapApiLoaded: false,
        mapInstance: null,
        mapApi: null,
        geoCoder: null,
        places: [],
        center: [],
        zoom: 9,
        address: '',
        draggable: true,
        coordinate: {
            lat: null,
            lng: null
        },

    };

    componentWillMount() {
        this.setCurrentLocation();
    }


    onMarkerInteraction = (childKey, childProps, mouse) => {
        this.setState({
            draggable: false,
            lat: mouse.lat,
            lng: mouse.lng
        });
    }
    onMarkerInteractionMouseUp = (childKey, childProps, mouse) => {
        this.setState({ draggable: true });
        this._generateAddress();
    }
    _storeData = (value) => {

        try {
            const jsonValue = JSON.stringify(value);
            window.localStorage.setItem("geolocation", jsonValue);
            console.log(jsonValue,"json")
        } catch (e) {
            // saving error
            console.log("saving Error::", e);
        }
    };

    _onChange = ({ center, zoom,value }) => {
        this.setState({
            center: center,
            zoom: zoom,
           
           
        });

    }
   
    _onClick = (value) => {
        this.setState({
            lat: value.lat,
            lng: value.lng
        });        
        this._storeData(value)
        console.log(value,"value")

    }

    apiHasLoaded = (map, maps) => {
        this.setState({
            mapApiLoaded: true,
            mapInstance: map,
            mapApi: maps,
        });

        this._generateAddress();
    };

    addPlace = (place) => {
        this.setState({
            places: [place],
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng()
        });
        this._generateAddress()
    };

    _generateAddress() {
        const {
            mapApi
        } = this.state;

        const geocoder = new mapApi.Geocoder;

        geocoder.geocode({ 'location': { lat: this.state.lat, lng: this.state.lng } }, (results, status) => {
            console.log(results);
            console.log(status);
            if (status === 'OK') {
                if (results[0]) {
                    this.zoom = 12;
                    this.setState({ address: results[0].formatted_address });
                } else {
                    window.alert('No results found');
                }
            } else {
                window.alert('Geocoder failed due to: ' + status);
            }

        });
    }

    // Get Current Location Coordinates
    setCurrentLocation() {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.setState({
                    center: [position.coords.latitude, position.coords.longitude],
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                });
            });
        }
    }

    render() {
        const {
            places, mapApiLoaded, mapInstance, mapApi,
        } = this.state;

      

        return (
            <Wrapper>
                {mapApiLoaded && (
                    <div >
                        <AutoComplete map={mapInstance} mapApi={mapApi} addplace={this.addPlace} />
                    </div>
                )}
                <div style={{ height: '50vh', width: '100%' }}>

                    <GoogleMapReact
                        center={this.state.center}
                        zoom={this.state.zoom}
                        draggable={this.state.draggable}
                        onChange={this._onChange}

                        onChildMouseDown={this.onMarkerInteraction}
                        onChildMouseUp={this.onMarkerInteractionMouseUp}
                        onChildMouseMove={this.onMarkerInteraction}
                        onChildClick={() => console.log('child click')}
                        onClick={this._onClick}
                        // onClick={region => {
                        //     this._onClick({ coordinate: region });
                        //     console.log(this.state.coordinate)
                        //     storeData(region)
                        // }}
                        bootstrapURLKeys={{
                            key: 'AIzaSyCqDXws4tnwb5zmd4xjlg9dj8W_lhe0XLU',
                            libraries: ['places', 'geometry'],
                        }}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({ map, maps }) => this.apiHasLoaded(map, maps)}

                    >


                        <Marker
                            text={this.state.address}
                            lat={this.state.lat}
                            lng={this.state.lng}
                        />


                    </GoogleMapReact>
                </div>

                <div className="info-wrapper">
                    <div className="map-details">Latitude: <span>{this.state.lat}</span>, Longitude: <span>{this.state.lng}</span></div>
                    <div className="map-details">Zoom: <span>{this.state.zoom}</span></div>
                    <div className="map-details">Address: <span style={{ fontWeight: 'bold' }}>{this.state.address}</span></div>
                </div>


            </Wrapper >
        );
    }
}

export default MyGoogleMap;
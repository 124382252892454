import React, { useState, useEffect } from "react";
import axios from "axios";
import apiUrl from "../ApiUrl/ApiUrl";
import alertIcon from "../../images/alert.png";

const feedbackData = [
  {
    id: 1,
    name: "John Doe",
    email: "abc@gmail.com",
    feedback: "ksdhfgsdfgjsdgfweuisdj",
  },
  {
    id: 2,
    name: "John Doe",
    email: "abc@gmail.com",
    feedback: "werertertwerterterterererr",
  },
  {
    id: 3,
    name: "John Doe",
    email: "abc@gmail.com",
    feedback: "xcbcvbed",
  },
];

export const Feedback = () => {
  const [loading, setLoading] = useState(true);
  const [feedback, setFeedback] = useState([]);
  const [modal, setModal] = useState("");
  const [userId, setUserId] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(apiUrl + "feedback");
      setFeedback(response.data);
      setLoading(false);
    };
    fetchData();
  }, []);

  const updateFeedback = async (id, value) => {
    const response = await axios.put(apiUrl + `feedback/update/${id}`, {
      is_active: value,
    });
    console.log(response.data);
  };

  return (
    <>
      {/* Accept modal */}
      <div
        className="modal fade"
        id="acceptModal"
        tabindex="-1"
        aria-labelledby="acceptModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header border-bottom-0 mb-0"></div>
            <div className="modal-body">
              <div className="alert-img">
                <img src={alertIcon} alt="alert" />
              </div>
              <p className="h5 text-center">
                Are you Sure you want to{" "}
                {modal === "resolve" ? "Resolve" : "Reject"} Feedback..?
              </p>
            </div>
            <div className="modal-footer border-top-0">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              {modal === "resolve" ? (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => updateFeedback(userId, 1)}
                  data-bs-dismiss="modal"
                >
                  Resolve
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => updateFeedback(userId, 2)}
                  data-bs-dismiss="modal"
                >
                  Reject
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* main div */}
      <div className="events-table mt-5">
        <fieldset>
          <legend>Feedback</legend>
          {loading ? (
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : feedback && feedback.length === 0 ? (
            "No Feedback Found"
          ) : (
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">sr#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Feedback</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {feedback.map((item, ind) => (
                  <tr>
                    <td scope="col">{ind + 1}</td>
                    <td scope="col">{item.username}</td>
                    <td scope="col">{item.email}</td>
                    <td scope="col">{item.feedback}</td>
                    <td scope="col" className="float-right">
                      <div className="booking-btn approve-btn">
                        <button
                          data-bs-toggle="modal"
                          data-bs-target="#acceptModal"
                          onClick={() => {
                            setModal("resolve");
                            setUserId(item.id);
                          }}
                        >
                          Resolve
                        </button>
                        <button
                          data-bs-toggle="modal"
                          data-bs-target="#acceptModal"
                          onClick={() => {
                            setModal("reject");
                            setUserId(item.id);
                          }}
                        >
                          Reject
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </fieldset>
      </div>
    </>
  );
};

import React, { useState, useEffect } from "react";
import "./login.css";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import { addToken } from "../../store/tokenSlice";
import { useDispatch } from "react-redux";

const url = "https://enterbkapi.herokuapp.com/";

export const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit } = useForm();

  const fetchData = async (values) => {
    const response = await axios.post(url + "auth/login", values);
    if (response.data.message === "Logged in") {
      // dispatch(addToken(response.data.token));
      localStorage.setItem("serialized", response.data.token);
      navigate("dashboard");
      window.location.reload()
      setLoading(false);
    } else {
      setLoading(false);
      setError(response.data.message);
    }
  };
  const onSubmit = (data) => {
    setLoading(true);
    fetchData(data);
  };
  return (
    <div style={{backgroundColor:"pink"}}>
      <div className="login-footer">
        <div className="login-form">
          <p>Sign in with your credentials</p>
          <hr className="hr" />
          <div className="input-main">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-input">
                <EmailIcon style={{ color: "#8898aa", fontSize: "16px" }} />
                <input
                  type="text"
                  placeholder="Email"
                  name="email"
                  {...register("email")}
                  required
                />
              </div>
              <div className="form-input">
                <LockIcon style={{ color: "#8898aa", fontSize: "16px" }} />
                <input
                  type="password"
                  placeholder="Password"
                  required
                  {...register("password")}
                  name="password"
                />
              </div>
              <div className="signin-btn">
                <Button
                  variant="contained"
                  type="submit"
                >
                  Sign in
                </Button>
                {loading ? (
                  <div className="spinner-grow text-info" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <p className="login-error">{error}</p>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

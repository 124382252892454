import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./eventDetails.css";
import apiUrl from "../ApiUrl/ApiUrl";
import axios from "axios";
import alertIcon from "../../images/alert.png";

export const EventsDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname.split("/")[3];
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(apiUrl + `events/category/${atob(path)}`);
      const data = await response.json();
      setEvents(data);
      setLoading(false);
    };
    fetchData();
  }, []);
  const removeData2 = async (id) => {
    const result = await axios.delete(apiUrl + `events/delete/${id}`);
    console.log(result.data);
  };
  const removeData = (id) => {
    setEvents(events.filter((item) => item.id !== id));
    removeData2(id);
  };

  return (
    <>
      {/* Delete modal */}
      <div
        className="modal fade"
        id="deleteModal"
        tabindex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header border-bottom-0 mb-0"></div>
            <div className="modal-body">
              <div className="alert-img">
                <img src={alertIcon} alt="alert" />
              </div>
              <p className="h5 text-center">
                Are you Sure you want to delete Event..?
              </p>
            </div>
            <div className="modal-footer border-top-0">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => removeData(userId)}
                data-bs-dismiss="modal"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* main div */}
      <div className="events-table">
        <fieldset>
          <legend className="float-none">
            Events in {location.state.name}
          </legend>
          <div className="row">
            <div className="col booking-heading">
              <p>Name</p>
            </div>
            <div className="col booking-heading">
              <p>Email</p>
            </div>
            <div className="col booking-heading">
              <p>Time In</p>
            </div>
            <div className="col booking-heading">
              <p>Time Out</p>
            </div>
            <div className="col booking-heading">
              <p>Price</p>
            </div>
            <div className="col"></div>
          </div>
          {loading ? (
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : events.status === false ? (
            "Record not found"
          ) : (
            events.map((item) => (
              <div className="row" key={item.id}>
                <div className="col">
                  <p>{item.title}</p>
                </div>
                <div className="col">
                  <p>{item.contact_email}</p>
                </div>
                <div className="col">
                  <p>
                    {parseInt(item.start.split(":")[0]) % 12}:
                    {item.start.split(":")[1]}{" "}
                    {item.start.split(":")[0] >= 12 ? "PM" : "AM"}
                  </p>
                </div>
                <div className="col">
                  <p>
                    {parseInt(item.end.split(":")[0]) % 12}:
                    {item.end.split(":")[1]}{" "}
                    {item.end.split(":")[0] >= 12 ? "PM" : "AM"}
                  </p>
                </div>
                <div className="col">
                  <p>{item.price}</p>
                </div>
                <div className="col">
                  <div className="events-btn">
                    <button
                      onClick={() =>
                        navigate(
                          `/events/events-details/edit-events/${btoa(item.id)}`
                        )
                      }
                    >
                      Edit
                    </button>
                    <button
                      data-bs-toggle="modal"
                      data-bs-target="#deleteModal"
                      onClick={() => setUserId(item.id)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            ))
          )}
        </fieldset>
      </div>
    </>
  );
};

import React, { useState, useEffect } from "react";
import "./bookingDetails.css";
import axios from "axios";
import apiUrl from "../ApiUrl/ApiUrl";
import { useLocation } from "react-router-dom";
import moment from "moment";

export const BookingDetails = () => {
  const location = useLocation();
  const path = location.pathname.split("/")[3];
  const [bookingData, setBookingData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(apiUrl + `booking/${atob(path)}`);
      setBookingData(result.data);
      setLoading(false);
    };
    fetchData();
  }, []);

  const eventDate = new Date(bookingData[0] && bookingData[0].event_date);
  const eventDateString = `${eventDate.getFullYear()}-${(
    "0" +
    (eventDate.getMonth() + 1)
  ).slice(-2)}-${("0" + eventDate.getDate()).slice(-2)}`;

  return (
    <div className="events-table booking-table mt-4">
      <fieldset>
        <legend >Booking Details</legend>
        {loading ? (
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col booking-heading">
                <p>Order No</p>
              </div>
              <div className="col booking-heading">
                <p>Name</p>
              </div>
              <div className="col booking-heading">
                <p>Category</p>
              </div>
              <div className="col booking-heading">
                <p>Seats</p>
              </div>
              <div className="col booking-heading">
                <p>Price per seats</p>
              </div>
              <div className="col booking-heading">
                <p>Total Price</p>
              </div>
              <div className="col booking-heading">
                <p>Date</p>
              </div>
              <div className="col booking-heading">
                <p>Start Time</p>
              </div>
              <div className="col booking-heading">
                <p>End Time</p>
              </div>
              <div className="col booking-heading">
                <p>Reason</p>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <p>{bookingData[0] && bookingData[0].order_no}</p>
              </div>
              <div className="col">
                <p>{bookingData[0] && bookingData[0].user_name}</p>
              </div>
              <div className="col">
                <p>{bookingData[0] && bookingData[0].category}</p>
              </div>
              <div className="col">
                <p>{bookingData[0] && bookingData[0].seats}</p>
              </div>
              <div className="col">
                <p>{bookingData[0] && bookingData[0].price_per_seat}</p>
              </div>
              <div className="col">
                <p>{bookingData[0] && bookingData[0].total_price}</p>
              </div>
              <div className="col">
                <p>{eventDateString}</p>
              </div>
              <div className="col">
                <p>
                  {moment(
                    bookingData[0] && bookingData[0].start,
                    "hh:mm A"
                  ).format("hh:mm A")}
                </p>
              </div>
              <div className="col">
                <p>
                  {moment(
                    bookingData[0] && bookingData[0].end,
                    "hh:mm A"
                  ).format("hh:mm A")}
                </p>
              </div>
              <div className="col">
                <p>{bookingData[0] && bookingData[0].msg}</p>
              </div>
            </div>
          </>
        )}
      </fieldset>
    </div>
  );
};

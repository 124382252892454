import React, { useEffect, useState } from "react";
// import "./users.css";
import CountUp from "react-countup";
import axios from "axios";
import alertIcon from "../../images/alert.png";

const userUrl = "https://enterbkapi.herokuapp.com/";

export const AppUsers = () => {
  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState();
  const [loading, setLoading] = useState(true);
  const [active, setActive] = useState(1);

  const token2 = localStorage.getItem("token");
  let headers = {
    Accept: "*/*",
    Authorization: `Bearer ${token2}`,
  };
  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(userUrl + "users", {
        headers: headers,
      });
      setUsers(response.data);
      setLoading(false);
    };
    fetchData();
  }, [users]);

  const blockUser = async (id) => {
    const result = await axios.put(
      userUrl + `users/suspend/${id}`,
      {
        "": "",
      },
      {
        headers: headers,
      }
    );
  };
  const activeUser = async (id) => {
    const result = await axios.put(
      userUrl + `users/remsuspend/${id}`,
      {
        "": "",
      },
      {
        headers: headers,
      }
    );
  };

  const restoreUser = async (id) => {
    const result = await axios.put(
      userUrl + `users/restore/${id}`,
      {
        "": "",
      },
      {
        headers: headers,
      }
    );
  };

  const allUsers = users.filter((data) => data.is_active === 0);
  const approvedUsers = users.filter((data) => data.is_verified === 1);
  const blockData = users.filter((data) => data.is_suspended === 1);
  const deletedUsers = users.filter((data) => data.is_deleted === 1);

  return (
    <>
      {/* Delete modal */}
      <div
        className="modal fade"
        id="deleteModal"
        tabindex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header border-bottom-0 mb-0"></div>
            <div className="modal-body">
              <div className="alert-img">
                <img src={alertIcon} alt="alert" />
              </div>
              {active === 3 ? (
                <p className="h5 text-center">
                  Are you Sure you want to Active user..?
                </p>
              ) : (
                <p className="h5 text-center">
                  Are you Sure you want to Block user..?
                </p>
              )}
            </div>
            <div className="modal-footer border-top-0">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              {active === 3 ? (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => activeUser(userId)}
                  data-bs-dismiss="modal"
                >
                  Active
                </button>
              ) : active === 4 ? (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => restoreUser(userId)}
                  data-bs-dismiss="modal"
                >
                  Restore
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => blockUser(userId)}
                  data-bs-dismiss="modal"
                >
                  Block
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* main div */}
      <div className="mt-5 booking-btn">
        <div className="users-events">
          <div className="users">
            <div className="users-main">
              <CountUp
                end={
                  active === 1
                    ? allUsers.length
                    : active === 2
                    ? approvedUsers.length
                    : active === 3
                    ? blockData.length
                    : deletedUsers.length
                }
                className="count"
              />
            </div>
            <p>Users</p>
          </div>
        </div>
        <div>
          <button
            onClick={() => setActive(1)}
            className={active === 1 ? "active-btn" : ""}
          >
            All Users
          </button>
          <button
            onClick={() => setActive(2)}
            className={active === 2 ? "active-btn" : ""}
          >
            Approved Users
          </button>
          <button
            onClick={() => setActive(4)}
            className={active === 4 ? "active-btn" : ""}
          >
            Deleted Users
          </button>
          <button
            onClick={() => setActive(3)}
            className={active === 3 ? "active-btn" : ""}
          >
            Blocked Users
          </button>
        </div>
      </div>

      <div className="users-table">
        <fieldset>
          <legend>App Users</legend>
          {loading ? (
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : users && users.length === 0 ? (
            "No User Found"
          ) : (
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Sr#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {(active === 1
                  ? allUsers
                  : active === 2
                  ? approvedUsers
                  : active === 3
                  ? blockData
                  : deletedUsers
                ).map((user, ind) => (
                  <tr>
                    <th scope="row">{ind + 1}</th>
                    <td>{user.user_name}</td>
                    <td>{user.email}</td>
                    <td>{user.phone}</td>
                    <td>
                      <div className="user-delete-btn">
                        {active === 3 ? (
                          <button
                            data-bs-toggle="modal"
                            data-bs-target="#deleteModal"
                            onClick={() => setUserId(user.id)}
                          >
                            Active
                          </button>
                        ) : active === 4 ? (
                          <button
                            data-bs-toggle="modal"
                            data-bs-target="#deleteModal"
                            onClick={() => setUserId(user.id)}
                          >
                            Restore
                          </button>
                        ) : (
                          <button
                            data-bs-toggle="modal"
                            data-bs-target="#deleteModal"
                            onClick={() => setUserId(user.id)}
                          >
                            Block
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </fieldset>
      </div>
    </>
  );
};

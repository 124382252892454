import React, { useEffect, useState } from "react";
import "./editEvents.css";
import editIcon from "../../images/pencil.png";
import axios from "axios";
import apiUrl from "../ApiUrl/ApiUrl";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

export const EditEvents = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [events, setEvents] = useState([]);
  const path = location.pathname.split("/")[4];
  const [categoryData, setCategoryData] = useState([]);
  const [heading, setHeading] = useState("");
  const [updatedValue, setUpdatedValue] = useState();
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(true);
  const[editcount,seteditcout] = useState(false)
  // localStorage.setItem("save changes button",editcount)
  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(apiUrl + "category");
      setCategoryData(result.data);
    };
    const fetchData2 = async () => {
      const result = await axios.get(apiUrl + `events/${atob(path)}`);
      setEvents(result.data);
      setLoading(false);
    };
    fetchData();
    fetchData2();
  }, []);
  const { handleSubmit } = useForm();
  const handleEdit = async () => {
    const result = await axios.put(apiUrl + `events/update/${atob(path)}`, {
      id: events[0] && events[0].id,
      title: events[0] && events[0].title,
      category: events[0] && events[0].category,
      subTitle: events[0] && events[0].subTitle,
      venue: events[0] && events[0].venue,
      address: events[0] && events[0].address,
      lat: events[0] && events[0].location.latitude,
      lon: events[0] && events[0].location.longitude,
      start: events[0] && events[0].start,
      end: events[0] && events[0].end,
      price: events[0] && events[0].price,
      date: events[0] && events[0].date,
      place: events[0] && events[0].place,
      contact_email: events[0] && events[0].contact_email,
      flag: 0,
      updated_at: new Date(),
    });
    window.location.reload();
 
   
  };
  const handleEditDialogOpen = (title, value) => {
    setHeading(title);
    setValue(value);
  };
  const eventDate = new Date(events[0] && events[0].date);
  const eventDateString = `${eventDate.getFullYear()}-${(
    "0" +
    (eventDate.getMonth() + 1)
  ).slice(-2)}-${("0" + eventDate.getDate()).slice(-2)}`;

  const handleUpdateValue = () => {
    if (heading === "Title:") {
      events[0].title = updatedValue;
      handleEdit();
      seteditcout(true)
    }
    if (heading === "Category:") {
      events[0].category = updatedValue;
      handleEdit();
      seteditcout(true)

    }
    if (heading === "SubTitle:") {
      events[0].subTitle = updatedValue;
      handleEdit();
      seteditcout(true)

    }
    if (heading === "Venue:") {
      events[0].venue = updatedValue;
      handleEdit();
      seteditcout(true)

    }
    if (heading === "Address:") {
      events[0].address = updatedValue;
      handleEdit();
      
    }
    if (heading === "Lat:") {
      events[0].location.latitude = updatedValue;
      handleEdit();
    }
    if (heading === "Lon:") {
      events[0].location.longitude = updatedValue;
      handleEdit();
    }
    if (heading === "Start Time:") {
      events[0].start = updatedValue;
      handleEdit();
    }
    if (heading === "End Time:") {
      events[0].end = updatedValue;
      handleEdit();
    }
    if (heading === "Price:") {
      events[0].price = updatedValue;
      handleEdit();
    }
    if (heading === "Date:") {
      events[0].date = updatedValue;
      handleEdit();
    }
    if (heading === "Place:") {
      events[0].place = updatedValue;
      handleEdit();
    }
    if (heading === "Email:") {
      events[0].contact_email = updatedValue;
      handleEdit();
    }
  };
  return (
    <>
      {loading ? (
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      ) : (
        <>
          {/* add user modal */}
          <div
            className="modal fade"
            id="editModal"
            tabindex="-1"
            aria-labelledby="editModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <form onSubmit={handleSubmit(handleUpdateValue)}>
                  <div className="modal-header">
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body px-4">
                    <div className="user-input">
                      <p>{heading}</p>
                      {heading === "Category:" ? (
                        <select
                          className="form-select small-input"
                          name="category"
                          onChange={(e) => setUpdatedValue(e.target.value)}
                          required
                        >
                          <option value="">Select</option>
                          {categoryData.map((item) => (
                            <>
                              <option value={item.id} key={item.id}>
                                {item.name}
                              </option>
                            </>
                          ))}
                        </select>
                      ) : heading === "Start Time:" ||
                        heading === "End Time:" ? (
                        <input
                          type="time"
                          className="form-control small-input"
                          name="start"
                          onChange={(e) => setUpdatedValue(e.target.value)}
                          required
                        />
                      ) : heading === "Date:" ? (
                        <input
                          type="date"
                          className="form-control small-input"
                          onChange={(e) => setUpdatedValue(e.target.value)}
                          name="date"
                          required
                        />
                      ) : (
                        <input
                          type="text"
                          name="txtUpdatedValue"
                          placeholder={value}
                          onChange={(e) => setUpdatedValue(e.target.value)}
                          required
                        />
                      )}
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="submit" onClick={()=> seteditcout(true)} className="btn btn-primary">
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div>
            <div className="events-table">
              <fieldset>
                <legend className="float-none">Edit Event</legend>
                <div className="edit-event-main">
                  <div className="edit-events">
                    <p>Title:</p>
                    <p>{events[0] && events[0].title}</p>
                  </div>
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#editModal"
                    onClick={() =>
                      handleEditDialogOpen(
                        "Title:",
                        events[0] && events[0].title
                      )
                    }
                  >
                    <img alt="Edit" src={editIcon} className="edit-icon" />
                  </button>
                </div>
                <div className="edit-event-main">
                  <div className="edit-events">
                    <p>Email:</p>
                    <p>{events[0] && events[0].contact_email}</p>
                  </div>
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#editModal"
                    onClick={() =>
                      handleEditDialogOpen(
                        "Email:",
                        events[0] && events[0].contact_email
                      )
                    }
                  >
                    <img alt="Edit" src={editIcon} className="edit-icon" />
                  </button>
                </div>
                <div className="edit-event-main">
                  <div className="edit-events">
                    <p>Category:</p>
                    <p>{events[0] && events[0].category}</p>
                  </div>
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#editModal"
                    onClick={() =>
                      handleEditDialogOpen(
                        "Category:",
                        events[0] && events[0].category
                      )
                    }
                  >
                    <img alt="Edit" src={editIcon} className="edit-icon" />
                  </button>
                </div>
                <div className="edit-event-main">
                  <div className="edit-events">
                    <p>SubTitle:</p>
                    <p>{events[0] && events[0].subTitle}</p>
                  </div>
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#editModal"
                    onClick={() =>
                      handleEditDialogOpen(
                        "SubTitle:",
                        events[0] && events[0].subTitle
                      )
                    }
                  >
                    <img alt="Edit" src={editIcon} className="edit-icon" />
                  </button>
                </div>
                <div className="edit-event-main">
                  {/* <div className="edit-events">
                    <p>Venue:</p>
                    <p>{events[0] && events[0].venue}</p>
                  </div> */}
                  {/* <button
                    data-bs-toggle="modal"
                    data-bs-target="#editModal"
                    onClick={() =>
                      handleEditDialogOpen(
                        "Venue:",
                        events[0] && events[0].venue
                      )
                    }
                  >
                    <img alt="Edit" src={editIcon} className="edit-icon" />
                  </button> */}
                </div>
                <div className="edit-event-main">
                  <div className="edit-events">
                    <p>Address:</p>
                    <p>{events[0] && events[0].address}</p>
                  </div>
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#editModal"
                    onClick={() =>
                      handleEditDialogOpen(
                        "Address:",
                        events[0] && events[0].address
                      )
                    }
                  >
                    <img alt="Edit" src={editIcon} className="edit-icon" />
                  </button>
                </div>
                <div className="edit-event-main">
                  <div className="edit-events">
                    <p>Lat:</p>
                    <p>{events[0] && events[0].location.latitude}</p>
                  </div>
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#editModal"
                    onClick={() =>
                      handleEditDialogOpen(
                        "Lat:",
                        events[0] && events[0].location.latitude
                      )
                    }
                  >
                    <img alt="Edit" src={editIcon} className="edit-icon" />
                  </button>
                </div>
                <div className="edit-event-main">
                  <div className="edit-events">
                    <p>Lon:</p>
                    <p>{events[0] && events[0].location.longitude}</p>
                  </div>
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#editModal"
                    onClick={() =>
                      handleEditDialogOpen(
                        "Lon:",
                        events[0] && events[0].location.longitude
                      )
                    }
                  >
                    <img alt="Edit" src={editIcon} className="edit-icon" />
                  </button>
                </div>
                <div className="edit-event-main">
                  <div className="edit-events">
                    <p>Start Time:</p>
                    <p>
                      {moment(events[0] && events[0].start, "hh:mm A").format(
                        "hh:mm A"
                      )}
                    </p>
                  </div>
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#editModal"
                    onClick={() =>
                      handleEditDialogOpen(
                        "Start Time:",
                        events[0] && events[0].start
                      )
                    }
                  >
                    <img alt="Edit" src={editIcon} className="edit-icon" />
                  </button>
                </div>
                <div className="edit-event-main">
                  <div className="edit-events">
                    <p>End Time:</p>
                    <p>
                      {moment(events[0] && events[0].end, "hh:mm A").format(
                        "hh:mm A"
                      )}
                    </p>
                  </div>
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#editModal"
                    onClick={() =>
                      handleEditDialogOpen(
                        "End Time:",
                        events[0] && events[0].end
                      )
                    }
                  >
                    <img alt="Edit" src={editIcon} className="edit-icon" />
                  </button>
                </div>
                <div className="edit-event-main">
                  <div className="edit-events">
                    <p>Price:</p>
                    <p>{events[0] && events[0].price}</p>
                  </div>
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#editModal"
                    onClick={() =>
                      handleEditDialogOpen(
                        "Price:",
                        events[0] && events[0].price
                      )
                    }
                  >
                    <img alt="Edit" src={editIcon} className="edit-icon" />
                  </button>
                </div>
                <div className="edit-event-main">
                  <div className="edit-events">
                    <p>Date:</p>
                    <p>{eventDateString}</p>
                  </div>
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#editModal"
                    onClick={() =>
                      handleEditDialogOpen("Date:", events[0] && events[0].date)
                    }
                  >
                    <img alt="Edit" src={editIcon} className="edit-icon" />
                  </button>
                </div>
                <div className="edit-event-main">
                  {/* <div className="edit-events">
                    <p>Place:</p>
                    <p>{events[0] && events[0].place}</p>
                  </div> */}
                  {/* <button
                    data-bs-toggle="modal"
                    data-bs-target="#editModal"
                    onClick={() =>
                      handleEditDialogOpen(
                        "Place:",
                        events[0] && events[0].place
                      )
                    }
                  >
                    <img alt="Edit" src={editIcon} className="edit-icon" />
                  </button> */}
                </div>
              </fieldset>
    <button type="submit" className="btn btn-primary form-submit" onClick={()=>navigate("/events")}>
                Save changes
              </button> 
             
            </div>
          </div>
        </>
      )}
    </>
  );
};

import "./App.css";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { Sidebar } from "./components/Sidebar/Sidebar";
import { Users } from "./components/Users/Users";
import { Events } from "./components/Events/Events";
import { EventsDetails } from "./components/EventsDetails/EventsDetails";
import { Booking } from "./components/Booking/Booking";
import { BookingDetails } from "./components/BookingDetails/BookingDetails";
import { EditEvents } from "./components/EditEvents/EditEvents";
import { Login } from "./components/login/Login";
import { Provider } from "react-redux";
import store from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { Dashboard } from "./components/Dashboard/Dashboard";
import { CreateEvents } from "./components/CreateEvents/CreateEvents";
import { AppUsers } from "./components/AppUsers/AppUsers";
import { Feedback } from "./components/Feedback/Feedback";
import { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const location = useLocation();
  const navigate = useNavigate()
  const [user, setUser] = useState("")
  useEffect(() => {
    if (localStorage.getItem("serialized") === null) {
      setUser("")
      navigate("/")
    }
    else {
      // navigate("/dashboard")
      setUser("us")

    }
  }, [])

  return (
    <div>
      {location.pathname !== "/" ? <Sidebar /> : location.pathname === "/" && user.length !== 0 ? <Sidebar /> : ""}
      <Provider store={store}>
        {user.length === 0 ? <Routes>
          <Route path="/" element={<Login />} />


        </Routes> :

          <div className="components">

            <Routes>
              <Route path="/Dashboard" element={<Dashboard />} />
              <Route path="/" element={<Dashboard />} />

              <Route path="/users" element={<Users />} />
              <Route path="/events" element={<Events />} />
              <Route
                path="events/events-details/:id"
                element={<EventsDetails />}
              />
              <Route path="/booking" element={<Booking />} />
              <Route
                path="/booking/booking-details/:id"
                element={<BookingDetails />}
              />
              <Route
                path="events/events-details/edit-events/:id"
                element={<EditEvents />}
              />
              <Route path="/events/create-events" element={<CreateEvents />} />
              <Route path="/app-users" element={<AppUsers />} />
              <Route path="/feedback" element={<Feedback />} />

            </Routes>
          </div>
        }

      </Provider>
    </div>
  );
}

export default App;

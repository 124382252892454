import React, { useEffect, useState } from "react";
import "./createEvents.css";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Geocode from "react-geocode";
import { BlockPicker } from "react-color";
import MyGoogleMap from "../MyGooglemap";

const url = "https://enterbkapi.herokuapp.com/api/";
const url2 = "https://enterbkapi.herokuapp.com/api/upload";

export const CreateEvents = (props) => {
  const [categoryData, setCategoryData] = useState([]);
  const [blockPickerColor, setBlockPickerColor] = useState("#37d67a");
  const [show, setShow] = useState(false);
  const[checkboxstate,setcheckboxstate] =  useState(false)
  const ToggleShow = () => {
    setShow(!show)
  }
  
  const [location, setLocation] = useState(null)
  
  const getData = () => {
    try {
      const jsonValue = window.localStorage.getItem("geolocation");
      setLocation(jsonValue != null ? JSON.parse(jsonValue) : null);
      console.log(jsonValue, "json");
      return jsonValue != null ? JSON.parse(jsonValue) : null;
      // console.log(jsonValue, "json");
    } catch (e) {
      console.log(e, "err")
    }
  };
    useEffect(() => {

      getData()

  }, [])
  console.log(location,"lati")

  const [addInput, setAddInput] = useState([{ eventImg: null }]);
  const [loading, setLoading] = useState();

  let q = [];
  const getGeo = async (values) => {
    setLoading(true);


    console.log(values.address, "dsf")
    Geocode.setApiKey("AIzaSyCqDXws4tnwb5zmd4xjlg9dj8W_lhe0XLU");
    Geocode.fromAddress(values.address).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        console.log(lat, lng)
        uploadImages(values, lat, lng);
      },
      (error) => {
        console.log(error)
        toast.error("Something is wrong with your address. Please confirm if you added correct address");
      }
    );
  }

  const uploadImages = async (values, lat, lng) => {
    for (let i = 0; i < addInput.length; i++) {
      const data = new FormData();
      const filename = Date.now() + addInput[i].eventImg.name;
      data.append("name", filename);
      data.append("photo", addInput[i].eventImg);
      console.log(data)

      try {
        const res = await axios.post("https://enterbkapi.herokuapp.com/upload/image", data);
        console.log(res.data);
        q[i]=res.data.url
        // q[i] = res.data.url
      } catch (err) {
        console.log(err);
      }
    }
    const result = await axios.post(url + "events", {
      title: values.title,
      category: values.category,
      is_selectable: "select",
      contact_email: values.contact_email,
      subTitle: values.subTitle,
      venue: "",
      address: values.address,
      lat: lat,
      lon: lng,
      colorcode:blockPickerColor,
      start: values.start,
      end: values.end,
      price: values.price,
      date: values.date,
      place: "",
      img1: q[0],
      img2: q[1],
      img3: q[2],
      img4: q[3],
      img5: q[4],
      flag: 0,
      created_by: 0,
    });
    console.log(result,"result")
    setLoading(false);
    if (result.data.status === true) {
      toast.success("Event Created Successfully");
    } else {
      toast.error("Event Creation Failed");
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(url + "category");
      setCategoryData(result.data);
    };
    fetchData();
  }, []);

  const handleAddImg = () => {
    setAddInput([...addInput, { eventImg: null }]);
  };
  const handleRemoveImg = (ind) => {
    const list = [...addInput];
    list.splice(ind, 1);
    setAddInput(list);
  };
  const handleChangeImg = (e, ind) => {
    const list = [...addInput];
    list[ind].eventImg = e.target.files[0];
    setAddInput(list);
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      category: "",
      is_selectable: "",
      subTitle: "",
      contact_email: "",
      address: "",
      lat: "",
      lon: "",
      start: "",
      end: "",
      price: "",
      date: "",
      img1: "",
      img2: "",
      img3: "",
      img4: "",
      img5: "",
      flag: 0,
      created_by: "",
    },
    onSubmit: (values) => {
      getGeo(values)
      console.log(values);
    },
    validationSchema: yup.object({
      title: yup.string().required("Please Select Title."),
      category: yup.string().required("Category is required."),
      subTitle: yup.string().required("Subtitle is required."),
      contact_email: yup
        .string()
        .required("Email is required.")
        .email("Email is not valid."),
      address: yup.string().required("Address is required."),
      lat: yup.string().required("Latitude is required."),
      lon: yup.string().required("Longitude is required."),
      // start: yup.string().required("Please select start time"),
      // end: yup.string().required("Please select end time"),
      // price: yup.number().required("Price is required."),
     }),
  });
  return (
    <div className="container my-5">
      <ToastContainer />
      <div className="create-events-form">
        <p>Create Events</p>
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-3">
            <label className="form-label">Title*</label>
            <input
              type="text"
              className="form-control"
              id="title"
              onChange={formik.handleChange}
              value={formik.values.title}
              required
            />
            {formik.errors.title ? (
              <div className="error">{formik.errors.title}</div>
            ) : (
              ""
            )}
          </div>
          <div className="d-flex">
            <div className="mb-3">
              <label className="form-label">Category*</label>
              <select
                className="form-select small-input"
                name="category"
                value={formik.values.category}
                onChange={formik.handleChange}
                id="category"
                required
              >
                <option value="">Select</option>
                {categoryData.map((item) => (
                  <>
                    <option value={item.id}>{item.name}</option>
                  </>
                ))}
              </select>
              {formik.errors.category ? (
                <div className="error">{formik.errors.category}</div>
              ) : (
                ""
              )}
            </div>
           
            <div >
 
              {/* {formik.errors.subTitle ? (
              <div className="error">{formik.errors.subTitle}</div>
            ) : (
              ""
            )} */}
            </div>
             
          </div>
          <div className="mb-3">
            <label className="form-label">Description*</label>
            <input
              type="text"
              className="form-control"
              id="subTitle"
              onChange={formik.handleChange}
              value={formik.values.subTitle}
              required
            />
            {formik.errors.subTitle ? (
              <div className="error">{formik.errors.subTitle}</div>
            ) : (
              ""
            )}
          </div>
          <div className="mb-3">
            <label className="form-label">Organizer Email*</label>
            <input
              type="text"
              className="form-control"
              id="contact_email"
              onChange={formik.handleChange}
              value={formik.values.contact_email}
              required
            />
            {formik.errors.contact_email ? (
              <div className="error">{formik.errors.contact_email}</div>
            ) : (
              ""
            )}
          </div>
          {/* <div className="mb-3">
            <label className="form-label">Venue*</label>
            <input
              type="text"
              className="form-control"
              id="venue"
              onChange={formik.handleChange}
              value={formik.values.venue}
              required
            />
            {formik.errors.venue ? (
              <div className="error">{formik.errors.venue}</div>
            ) : (
              ""
            )}
          </div> */}
          <div className="mb-3">
            <label className="form-label">Address*</label>
            <input
              type="text"
              className="form-control"
              id="address"
              onChange={formik.handleChange}
              value={formik.values.address}
              required
            />
            {formik.errors.address ? (
              <div className="error">{formik.errors.address}</div>
            ) : (
              ""
            )}
          </div>
          <div className="mb-3">
            <label className="form-label">Latitude*</label>
            <input
              type="text"
              className="form-control"
              id="lat"
              onChange={formik.handleChange}
              value={formik.values.lat}
              required
            />
            {formik.errors.lat ? (
              <div className="error">{formik.errors.lat}</div>
            ) : (
              ""
            )}
          </div>
          <div className="mb-3">
            <label className="form-label">Longitude*</label>
            <input
              type="text"
              className="form-control"
              id="lon"
              onChange={formik.handleChange}
              value={formik.values.lon}
              required
            />
            {formik.errors.lon ? (
              <div className="error">{formik.errors.lon}</div>
            ) : (
              ""
            )}
          </div>
          {/* <MyGoogleMap lat={props.lat} lng={props.lng}/> */}
          <div className="mb-3">
            <label className="form-label">Start Time*</label>
            <input
              type="time"
              className="form-control small-input"
              id="start"
              onChange={formik.handleChange}
              value={formik.values.start.to}
               
            />
            {formik.errors.start ? (
              <div className="error">{formik.errors.start}</div>
            ) : (
              ""
            )}
          </div>
          <div className="mb-3">
            <label className="form-label">End Time*</label>
            <input
              type="time"
              className="form-control small-input"
              id="end"
              onChange={formik.handleChange}
              value={formik.values.end}
               
            />
            {formik.errors.end ? (
              <div className="error">{formik.errors.end}</div>
            ) : (
              ""
            )}
          </div>
          <div className="mb-3">
            <label className="form-label">
              price*
              <input
                type="number"
                className="form-control small-input"
                id="price"
                onChange={formik.handleChange}
                value={formik.values.price}
                 
              />
              {formik.errors.price ? (
                <div className="error">{formik.errors.price}</div>
              ) : (
                ""
              )}
            </label>
          </div>
          <div className="mb-3">
          
         
            <label className="form-label">
              Date*
              {
                checkboxstate ?  <input
                type="date"
                className="form-control small-input"
                id="date"
                onChange={formik.handleChange}
                value={formik.values.date}
                disabled


                
              /> :  
               <input
              type="date"
              className="form-control small-input"
              id="date"
              onChange={formik.handleChange}
              value={formik.values.date}
              required
              
            />
              }
             
              {formik.errors.date ? (
                <div className="error">{formik.errors.date}</div>
              ) : (
                ""
              )}
            </label>
            
            <input style={{marginLeft:"10%",marginRight:"1%"}} type="checkbox" defaultChecked = {checkboxstate}  onChange={()=> setcheckboxstate(!checkboxstate)} />
            <label>I don't want to add the date</label></div>
        
          {/* <div className="mb-3">
            <label className="form-label">Place*</label>
            <input
              type="text"
              className="form-control"
              id="place"
              onChange={formik.handleChange}
              value={formik.values.place}
              required
            />
            {formik.errors.place ? (
              <div className="error">{formik.errors.place}</div>
            ) : (
              ""
            )}
          </div> */}
          {addInput.map((addImg, ind) => (
            <>
              <div className="mb-3">
                <input
                  type="file"
                  onChange={(e) => handleChangeImg(e, ind)}
                  id="eventImg"
                  // value={addImg.eventImg}
                  required
                />
                {addInput.length > 1 && (
                  <button
                    type="button"
                    className="romove-btn"
                    onClick={() => {
                      handleRemoveImg(ind);
                    }}
                  >
                    Remove
                  </button>
                )}
              </div>
              <div className="mb-3 add-more-btn">
                {addInput.length - 1 === ind && addInput.length < 5 && (
                  <button type="submit" onClick={handleAddImg}>
                    Add more
                  </button>
                )}
              </div>
            </>
          ))}
          <button type="submit" className="btn btn-primary form-submit">
            Submit
          </button>
          {loading ? (
            <div className="spinner-grow text-info mx-2" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            ""
          )}
        </form>
      </div>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import "./users.css";
import { useFormik } from "formik";
import * as yup from "yup";
import CountUp from "react-countup";
import { useSelector } from "react-redux";
import apiUrl from "../ApiUrl/ApiUrl";
import axios from "axios";
import alertIcon from "../../images/alert.png";
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';

export const Users = () => {
  const token = useSelector((state) => state.token);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [idr, setIdr] = useState(false)

  const [users, setUsers] = useState([]);
  const [events, setEvents] = useState([]);
  const [userId, setUserId] = useState();
  const [status, setStatus] = useState();
  const [loading, setLoading] = useState(true);
  const token2 = localStorage.getItem("token");
  let headers = {
    Accept: "*/*",
    Authorization: `Bearer ${token2}`,
  };
  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(apiUrl + "agent", {
        headers: headers,
      });
      setUsers(response.data);
      setLoading(false);
      // console.log(response.data);
    };
    const fetchEvents = async () => {
      const response = await axios.get(apiUrl + "events", {
        headers: headers,
      });
      setEvents(response.data);
    };
    fetchData();
    fetchEvents();
  }, [idr]);

  const deleteUsers = async (id) => {
    const result = await axios.delete(apiUrl + `agent/delete/${id}`, {
      headers: headers,
    });
    console.log(result.data);
  };
  const removeData = (id) => {
    setUsers(users.filter((item) => item.id !== id));
    deleteUsers(id);
  };

  const sendData = async (value) => {
    const response = await axios.post(apiUrl + "agent", value, {
      headers: headers,
    });
    setLoading(false);
    setStatus(response.data);
    window.location.reload();
  };

  const formik = useFormik({
    initialValues: {
      username: "",
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      console.log(values);
      sendData(values);
    },
    validationSchema: yup.object({
      username: yup
        .string()
        .min(3, "must be 3 character or more")
        .required("User Name is required."),
      email: yup
        .string()
        .email("Invalid email address")
        .required("Email is Required"),
      password: yup.string().required("password is required."),
    }),
  });

  return (
    <>
      {/* add user modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Enter User Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <form onSubmit={formik.handleSubmit}>
            <div className="modal-body">
              <div className="user-input">
                <p>User Name:</p>
                <input
                  type="text"
                  placeholder="Enter user Name"
                  id="username"
                  onChange={formik.handleChange}
                  value={formik.values.username}
                  required
                />
              </div>
              {formik.errors.username ? (
                <div className="error">{formik.errors.username}</div>
              ) : (
                ""
              )}
              <div className="user-input">
                <p>Email:</p>
                <input
                  type="email"
                  placeholder="Enter Email"
                  id="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  required
                />
              </div>
              {formik.errors.email ? (
                <div className="error">{formik.errors.email}</div>
              ) : (
                ""
              )}
              <div className="user-input">
                <p>Password:</p>
                <input
                  type="password"
                  placeholder="Enter Password"
                  id="password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  required
                />
              </div>
              {formik.errors.password ? (
                <div className="error">{formik.errors.password}</div>
              ) : (
                ""
              )}
            </div>
            <div className="modal-footer">
              {loading ? (
                <div class="spinner-grow text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              ) : (
                <p>{status && status.message}</p>
              )}
              <button
                type="submit"
                className="btn btn-primary"
                data-bs-toggle="modal"

                onClick={() => {
                  setLoading(true)
                  handleClose();
                  setIdr(!idr)
                  alert("User Added Successfully")
                }}
              >
                Save changes
              </button>
            </div>

          </form>
        </Modal.Body>

      </Modal>
      {/* <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Enter User Details
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="modal-body">
                <div className="user-input">
                  <p>User Name:</p>
                  <input
                    type="text"
                    placeholder="Enter user Name"
                    id="username"
                    onChange={formik.handleChange}
                    value={formik.values.username}
                    required
                  />
                </div>
                {formik.errors.username ? (
                  <div className="error">{formik.errors.username}</div>
                ) : (
                  ""
                )}
                <div className="user-input">
                  <p>Email:</p>
                  <input
                    type="email"
                    placeholder="Enter Email"
                    id="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    required
                  />
                </div>
                {formik.errors.email ? (
                  <div className="error">{formik.errors.email}</div>
                ) : (
                  ""
                )}
                <div className="user-input">
                  <p>Password:</p>
                  <input
                    type="password"
                    placeholder="Enter Password"
                    id="password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    required
                  />
                </div>
                {formik.errors.password ? (
                  <div className="error">{formik.errors.password}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="modal-footer">
                {loading ? (
                  <div class="spinner-grow text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <p>{status && status.message}</p>
                )}
                <button
                  type="submit"
                  className="btn btn-primary"
                  data-bs-toggle="modal"

                  onClick={() => {
                    setLoading(true)
                    setVisible(false)

                  }}
                >
                  Save changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div> */}

      {/* Delete modal */}
      <div
        className="modal fade"
        id="deleteModal"
        tabindex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header border-bottom-0 mb-0"></div>
            <div className="modal-body">
              <div className="alert-img">
                <img src={alertIcon} alt="alert" />
              </div>
              <p className="h5 text-center">
                Are you Sure you want to delete user..?
              </p>
            </div>
            <div className="modal-footer border-top-0">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => removeData(userId)}
                data-bs-dismiss="modal"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* main div */}
      <div className="users-events">
        <div className="flex">
          <div className="users">
            <div className="users-main">
              <CountUp
                end={users.length === 0 ? 0 : users.length}
                className="count"
              />
            </div>
            <p>Users</p>
          </div>
          <div className="users">
            <div className="users-main">
              <CountUp
                end={events.length === 0 ? 0 : events.length}
                className="count"
              />
            </div>
            <p>Events</p>
          </div>
        </div>
        <div className="add-users">
          <button
            onClick={handleShow}
            type="button"

          >
            Add Users
          </button>
        </div>
      </div>
      <div className="users-table">
        <fieldset>
          <legend >Active Users</legend>
          {loading ? (
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : users && users.length === 0 ? (
            "No User Found"
          ) : (
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Sr#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col" ></th>
                </tr>
              </thead>
              <tbody>
                {users &&
                  users.map((user, ind) => (
                    <tr>
                      <th scope="row">{ind + 1}</th>
                      <td>{user.username}</td>
                      <td>{user.email}</td>
                      <td>
                        <div className="user-delete-btn">
                          <button
                            data-bs-toggle="modal"
                            data-bs-target="#deleteModal"
                            onClick={() => setUserId(user.id)}
                          >
                            Delete
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </fieldset>
      </div>
    </>
  );
};

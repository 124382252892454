import React from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Chart from "./Chart";
import Deposits from "./Deposits";
import RecentOrders from "./RecentOrders";

export const Dashboard = () => {
  return (
    <div>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: "auto",
          overflow: "auto",
        }}
      >
        <Container maxWidth="lg" sx={{ mt: 2, mb: 4,boxShadow: 3 }}>
          <Grid container spacing={3}>
            {/* Chart */}
            <Grid item xs={12} md={8} lg={9}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  height: 240,
                }}
              >
                <Chart />
              </Paper>
            </Grid>
            {/* Recent Deposits */}
            <Grid item xs={12} md={4} lg={3}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  height: 240,
                }}
              >
                <Deposits />
              </Paper>
            </Grid>
            {/* Recent Orders */}
            <Grid item xs={12}>
              <Paper sx={{ p: 2, display: "flex", flexDirection: "column" ,}}>
                <RecentOrders />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
};

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./booking.css";
import axios from "axios";
import apiUrl from "../ApiUrl/ApiUrl";
import alertIcon from "../../images/alert.png";
import moment from "moment";

export const Booking = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState(1);
  const [bookingData, setBookingData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState();
  const [categoryData, setCategoryData] = useState([]);
  const [filterCategory, setFilterCategory] = useState();
  const [filterDate, setFilterDate] = useState();
  const [modal, setModal] = useState("");
  const [status, setStatus] = useState();
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [message, setMessage] = useState("");
  const [bid ,setbid] = useState()

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(apiUrl + "booking");
      setBookingData(result.data);
      console.log(result.data,"ds")
      setLoading(false);
    };
    const categoryData = async () => {
      const result = await axios.get(apiUrl + "category");
      setCategoryData(result.data);
    };
    fetchData();
    categoryData();
  }, []);

  const approveBooking = async (id,bid, value) => {
    const result = await axios.put(apiUrl + "booking/confirm/" + id, {
      status: value,
    });
    sendNotification(bid);
    window.location.reload();

  };

  const updateTime = async (id,bid) => {
    const result = await axios.put(apiUrl + "booking/changetime/" + bid, {
      time_in: startTime,
      time_out: endTime,
    });
    sendNotification(bid);
  };

  const sendNotification = async (id) => {
    const result = await axios.post(apiUrl + "push/" + id, {
      message: message,
    });
  };

  const pendingData = bookingData.status  === false ?"": bookingData.filter((data) => data.status === 0);
  const approvedData = bookingData.status=== false ?"":bookingData.filter((data) => data.status === 1);
  const refusedData = bookingData.status=== false ?"":bookingData.filter((data) => data.status === 2);
  const timeChangeReq = bookingData.status === false?"":bookingData.filter(
    (data) => data.is_selectable === true
  );

  const categoryFilter = (
    active === 1
      ? pendingData
      : active === 2
      ? approvedData
      : active === 3
      ? refusedData
      : timeChangeReq
  ).filter((data) => data.category === filterCategory);
  const dateFilter = (
    active === 1
      ? pendingData
      : active === 2
      ? approvedData
      : active === 3
      ? refusedData
      : timeChangeReq
  ).filter(
    (data) => moment(data.event_date).format("YYYY-MM-DD") === filterDate
  );
  const bothFilter = categoryFilter.filter(
    (data) => moment(data.event_date).format("YYYY-MM-DD") === filterDate
  );

  const [searchInput, setSearchInput] = useState([]);

  const handleInputChange = (e) => {
    var dm = e.target.value;
    var str = dm.toLowerCase();
    var debug = (
      active === 1
        ? pendingData
        : active === 2
        ? approvedData
        : active === 3
        ? refusedData
        : active === 4
        ? timeChangeReq
        : ""
    ).filter((x) => x["order_no"].toString().toLowerCase().includes(str));
    setSearchInput(debug);
    if (dm === "") {
      setSearchInput([]);
    }
  };

  const filterBooking = () => {
    if (filterCategory === "all" && filterDate === undefined) {
      if (searchInput.length === 0) {
        return active === 1
          ? pendingData
          : active === 2
          ? approvedData
          : active === 3
          ? refusedData
          : active === 4
          ? timeChangeReq
          : "";
      } else {
        return searchInput;
      }
    } else if (filterCategory === "all" && filterDate !== undefined) {
      return dateFilter;
    } else if (filterCategory !== undefined && filterDate !== undefined) {
      return bothFilter;
    } else if (filterDate !== undefined) {
      return dateFilter;
    } else if (filterCategory !== undefined) {
      return categoryFilter;
    } else {
      if (searchInput.length === 0) {
        return active === 1
          ? pendingData
          : active === 2
          ? approvedData
          : active === 3
          ? refusedData
          : active === 4
          ? timeChangeReq
          : "";
      } else {
        return searchInput;
      }
    }
  };

  const getTimeDiff = (time1) => {
    const currentDate = new Date();
    const startTime = moment(currentDate, "HH:mm:ss a");
    const endTime = moment(time1, "HH:mm:ss a");
    const duration = moment.duration(endTime.diff(startTime));
    const hours = parseInt(duration.asHours());
    const minutes = parseInt(duration.asMinutes()) % 60;
    // console.log(hours, minutes);
    return minutes;
  };

  return (
    <>
      {/* Delete modal */}
      <div
        className="modal fade"
        id="deleteModal"
        tabindex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header border-bottom-0 mb-0"></div>
            <div className="modal-body">
              <div className="alert-img">
                <img src={alertIcon} alt="alert" />
              </div>
              <p className="h5 text-center">
                Are you Sure you want to{" "}
                {modal === "approve" ? "Approve" : "Reject"} Booking..?
              </p>
            </div>
            <div className="modal-footer border-top-0">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              {modal === "approve" ? (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    approveBooking(userId,bid, 1);
                    setMessage("Your booking has been approved");
                  }}
                  data-bs-dismiss="modal"
                >
                  Approve
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    approveBooking(userId,bid, 2);
                    setMessage("Your booking has been rejected");
                  }}
                  data-bs-dismiss="modal"
                >
                  Reject
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* change time modal */}
      <div
        className="modal fade"
        id="timeModal"
        tabindex="-1"
        aria-labelledby="timeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="timeModalLabel">
                Change Time Request
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
          
              <div className="modal-body">
                <div className="user-input">
                  <p>Start Time:</p>
                  <input
                    type="time"
                    id="start"
                    onChange={(e) => setStartTime(e.target.value)}
                    required
                  />
                </div>

                <div className="user-input">
                  <p>End Time:</p>
                  <input
                    type="time"
                    id="end"
                    onChange={(e) => setEndTime(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="modal-footer">
                {loading ? (
                  <div className="spinner-grow text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <p>{status && status.message}</p>
                )}
               { startTime && endTime ?<button
                  type="submit"
                  className="btn btn-primary"
                  onClick={() => {
                    setLoading(true);
                    updateTime(userId,bid);
                    setMessage("You have receive the change time request");
                  }}
                >
                  Send
                </button>:""}
              </div>
            
          </div>
        </div>
      </div>

      {/* main div */}
      <div className="mt-5 booking-btn">
        <div>
          <button
            onClick={() => setActive(1)}
            className={active === 1 ? "active-btn" : ""}
          >
            Pending
          </button>
          <button
            onClick={() => setActive(2)}
            className={active === 2 ? "active-btn" : ""}
          >
            Approved
          </button>
          <button
            onClick={() => setActive(3)}
            className={active === 3 ? "active-btn" : ""}
          >
            Refused
          </button>
          {/* <button
            onClick={() => setActive(4)}
            className={active === 4 ? "active-btn" : ""}
          >
            Time Change Request
          </button> */}
        </div>
        <div className="float-end filter-booking">
          <h5 style={{marginTop:'3%'}}>Filter By:</h5>
          <div className="d-flex">
            <h6>Category</h6>
            <select
              className="form-select small-input"
              name="category"
              id="category"
              onChange={(e) => {
                setFilterCategory(e.target.value);
              }}
              required
            >
              <option value="all">All</option>
              {categoryData.map((item) => (
                <>
                  <option value={item.name}>{item.name}</option>
                </>
              ))}
            </select>
            <h6>Date</h6>
            <input
              type="date"
              className="form-control w-50"
              onChange={(e) => {
                setFilterDate(e.target.value);
              }}
            />
             <div>
          <input
            type="text"
            className="form-control"
            placeholder="Search..."
            onChange={handleInputChange}
          />
        </div>
          </div>
          
        </div>
       
      </div>
      <div className="events-table mt-5">
        <fieldset>
          <legend >Booking Details</legend>
          {loading ? (
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : bookingData && bookingData.length === 0 ? (
            "No Booking Found"
          ) : (
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">sr#</th>
                  <th scope="col">Order No</th>
                  <th scope="col">Name</th>
                  <th scope="col">Category</th>
                  <th scope="col">Event</th>
                  <th scope="col">Email</th>
                  <th scope="col">Seats</th>
                  <th scope="col">Price</th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {filterBooking().map((item, ind) => (
                  <tr
                    className={
                      getTimeDiff(item.created_at) > 30
                        ? "booking-tr"
                        : "booking-tr"
                    }
                  >
                    <td scope="col">{ind + 1}</td>
                    <td scope="col">{item.order_no}</td>
                    <td scope="col">{item.user_name}</td>
                    <td scope="col">{item.category}</td>
                    <td scope="col">{item.title}</td>
                    <td scope="col">{item.contact_details}</td>
                    <td scope="col">{item.seats}</td>
                    <td scope="col">{item.total_price}</td>
                    <td scope="col">
                      <div
                        className="view-more"
                        onClick={() =>
                          navigate(`booking-details/${btoa(item.id)}`)
                        }
                      >
                        <p>View More</p>
                      </div>
                    </td>
                    <td scope="col" className="float-right">
                      {active === 1 || active === 4 ? (
                        <div className="booking-btn approve-btn">
                          <button
                            data-bs-toggle="modal"
                            data-bs-target="#deleteModal"
                            onClick={() => {
                              setbid(item.id)
                              setUserId(item.order_no);
                              setModal("approve");
                            }}
                          >
                            Approve
                          </button>
                          <button
                            data-bs-toggle="modal"
                            data-bs-target="#deleteModal"
                            onClick={() => {
                              setbid(item.id)
                              setUserId(item.order_no);
                              setModal("reject");
                            }}
                          >
                            Reject
                          </button>
                          {active === 4 ? (
                            <button
                              data-bs-toggle="modal"
                              data-bs-target="#timeModal"
                              onClick={() => {
                                setbid(item.id)
                                setUserId(item.order_no);
                              }}
                            >
                              Time Change
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </fieldset>
      </div>
    </>
  );
};
